/* You can add global styles to this file, and also import other style files */
$primary: #27201E;
$secondary: #F05A29;
// $success: #28a745;
$success: #9EC407;
// $danger: #dc3545;
$danger: #EF4952;
$warning: #ffc107;
$info: #17a2b8;
$light: #f8f9fa;
$dark: #343a40;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "light": $light,
  "dark": $dark,
);

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons";
@import "~@ng-select/ng-select/themes/default.theme.css"