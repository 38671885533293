/* You can add global styles to this file, and also import other style files */
@import './main.scss';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';


@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
}

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    width: 100%;
}

.bg-e8fffc {
    background-color: #E8FFFC;
}
.bg-d5ebff {
    background-color: #D5EBFF;
}
.bg-f4f4f4 {
    background-color: #F4F4F4;
}
.bg-fff5f2 {
    background-color: #FFF5F2;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: #dc3545;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.btn-outline-secondary:hover {
  color: white;
}

// badge pills
.ag-grid-pill-info{
  background-color: #b3d3f1;
  color: #000;
}

.ag-grid-pill-warning{
  background-color: #fff9b3;
  color: #000;
}

.ag-grid-pill-success{
  background-color: #c5edbf;
  color: #000;
}

.ag-grid-pill-danger{
  background-color: #facbbd;
  color: #000;
}

.ag-grid-pill-secondary{
  background-color: #c9cbce;
  color: #000;
}


